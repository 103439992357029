//utils/imageUrl.js

export function imageUrl(idA, siteIdA, imageName) {
  return (
    "https://cdn-gbbu02.graphibox.eu/pub/" +
    siteIdA +
    "/medias/articles/" +
    idA +
    "/" +
    imageName
  );
}
